var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "订单号", prop: "outTradeNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入订单号" },
                model: {
                  value: _vm.form.outTradeNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "outTradeNo", $$v)
                  },
                  expression: "form.outTradeNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "商品描述", prop: "description" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入商品描述" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "商品描述", prop: "userId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入商品描述" },
                model: {
                  value: _vm.form.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userId", $$v)
                  },
                  expression: "form.userId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总金额，单位：分", prop: "totalAmount" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总金额，单位：分" },
                model: {
                  value: _vm.form.totalAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalAmount", $$v)
                  },
                  expression: "form.totalAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label:
                  "交易状态,SUCCESS：支付成功 NOTPAY：未支付 PAYERROR：支付失败(其他原因，如银行返回失败) PENDING：待付款",
                prop: "tradeState",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder:
                    "请输入交易状态,SUCCESS：支付成功 NOTPAY：未支付 PAYERROR：支付失败(其他原因，如银行返回失败) PENDING：待付款",
                },
                model: {
                  value: _vm.form.tradeState,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tradeState", $$v)
                  },
                  expression: "form.tradeState",
                },
              }),
            ],
            1
          ),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                {
                  attrs: { label: "微信系统产的订单号", prop: "transactionId" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入微信系统产的订单号" },
                    model: {
                      value: _vm.form.transactionId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "transactionId", $$v)
                      },
                      expression: "form.transactionId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }