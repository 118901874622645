<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="订单号" prop="outTradeNo" >
        <a-input v-model="form.outTradeNo" placeholder="请输入订单号" />
      </a-form-model-item>
      <a-form-model-item label="商品描述" prop="description" >
        <a-input v-model="form.description" placeholder="请输入商品描述" />
      </a-form-model-item>
      <a-form-model-item label="商品描述" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入商品描述" />
      </a-form-model-item>
      <a-form-model-item label="总金额，单位：分" prop="totalAmount" >
        <a-input v-model="form.totalAmount" placeholder="请输入总金额，单位：分" />
      </a-form-model-item>
      <a-form-model-item label="交易状态,SUCCESS：支付成功 NOTPAY：未支付 PAYERROR：支付失败(其他原因，如银行返回失败) PENDING：待付款" prop="tradeState" >
        <a-input v-model="form.tradeState" placeholder="请输入交易状态,SUCCESS：支付成功 NOTPAY：未支付 PAYERROR：支付失败(其他原因，如银行返回失败) PENDING：待付款" />
      </a-form-model-item>
      <a-form-model-item label="微信系统产的订单号" prop="transactionId" v-if="formType === 1">
        <a-input v-model="form.transactionId" placeholder="请输入微信系统产的订单号" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" v-if="formType === 1">
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTransRecordPurchase, addTransRecordPurchase, updateTransRecordPurchase } from '@/api/recyclable/transRecordPurchase'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        outTradeNo: null,
        description: null,
        userId: null,
        totalAmount: null,
        tradeState: null,
        transactionId: null,
        remark: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        outTradeNo: [
          { required: true, message: '订单号不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '订单号不能为空', trigger: 'blur' }
        ],
        totalAmount: [
          { required: true, message: '总金额，单位：分不能为空', trigger: 'blur' }
        ],
        tradeState: [
          { required: true, message: '交易状态,SUCCESS：支付成功 NOTPAY：未支付 PAYERROR：支付失败(其他原因，如银行返回失败) PENDING：待付款不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        outTradeNo: null,
        description: null,
        userId: null,
        totalAmount: null,
        tradeState: null,
        transactionId: null,
        remark: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTransRecordPurchase(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTransRecordPurchase(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addTransRecordPurchase(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
