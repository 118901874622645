/*
 * @Auth: linjituan
 * @Date: 2022-03-24 14:50:34
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-24 14:51:26
 */
import request from '@/utils/request'
const baseURL = '/rc-api'

// 查询购买记录列表
export function listTransRecordPurchase (query) {
  return request({
    url: '/internal/recyclable/transRecordPurchase/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询购买记录详细
export function getTransRecordPurchase (id) {
  return request({
    url: '/internal/recyclable/transRecordPurchase/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增购买记录
export function addTransRecordPurchase (data) {
  return request({
    url: '/internal/recyclable/transRecordPurchase',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改购买记录
export function updateTransRecordPurchase (data) {
  return request({
    url: '/internal/recyclable/transRecordPurchase',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除购买记录
export function delTransRecordPurchase (id) {
  return request({
    url: '/internal/recyclable/transRecordPurchase/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出购买记录
export function exportTransRecordPurchase (query) {
  return request({
    url: '/internal/recyclable/transRecordPurchase/export',
    baseURL,
    method: 'get',
    params: query
  })
}
